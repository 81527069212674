import React, { useEffect, useRef, useState } from 'react';
import { useElementSize } from 'usehooks-ts';
import { useRouter } from 'next/router';
import {
  Button as MuiButton,
  Popper,
  Box,
  Grow,
  ClickAwayListener,
  Paper as MuiPaper,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  Divider as MuiDivider,
  DividerProps,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';

export interface Option {
  label: string;
  value: string;
}
export interface FilterProps {
  id: string;
  items?: Option[];
  label: string;
  variant: 'contained' | 'outlined';
  multiple?: boolean;
  noClear?: boolean;
  defaultSelection?: string[];
  hidden?: boolean;
}

export const Filter = ({ id, label, items, variant, multiple, noClear, defaultSelection = [] }: FilterProps) => {
  const theme = useTheme();
  const router = useRouter();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [checked, setChecked] = useState<string[]>(defaultSelection ?? []);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (router.query[id]) {
      if (Array.isArray(router.query[id])) setChecked(router.query[id] as string[]);
      else if (typeof router.query[id] === 'string') setChecked([router.query[id] as string]);
    }
  }, [router.query]);

  const handleToggle = (value: string) => () => {
    if (multiple) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
      router.query[id] = newChecked;
      delete router.query.page;
      router.push(router, undefined, { shallow: true });
    } else {
      setChecked([value]);
      router.query[id] = value;
      delete router.query.page;
      router.push(router, undefined, { shallow: true });
    }
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClear = () => {
    setChecked([]);
    delete router.query[id];
    delete router.query.page;
    router.push(router, undefined, { shallow: true });
  };

  const getLabel = (): string => {
    const filterItem = items?.find((item) => item.value === checked[0]);
    if (filterItem) {
      let label = filterItem.label;
      if (checked.length > 1) label = `${label} (+${checked.length - 1})`;
      return label;
    }
    return label;
  };

  const open = Boolean(anchorEl);
  const { width } = useElementSize(anchorRef);
  let buttonClear = 'Clear All';

  return (
    <ErrorBoundary>
      <Button
        ref={anchorRef}
        onClick={handleClick}
        variant={variant}
        sx={{
          borderBottomLeftRadius: open ? 0 : 10,
          borderBottomRightRadius: open ? 0 : 10,
          '@media screen and (max-width: 1000px)': {
            borderBottomLeftRadius: open ? 0 : 10,
            borderBottomRightRadius: open ? 0 : 10,
          },
        }}
      >
        {getLabel()}
        {open ? (
          <ArrowDownwardIcon sx={{ fontSize: 'medium', color: variant === 'outlined' ? 'primary' : 'white' }} />
        ) : (
          <ArrowForwardIcon sx={{ fontSize: 'medium', color: variant === 'outlined' ? 'primary' : 'white' }} />
        )}
      </Button>

      <FilterList
        open={open}
        width={width}
        buttonClear={buttonClear}
        handleToggle={handleToggle}
        checked={checked}
        variant={variant}
        items={items}
        noClear={noClear}
        onClear={handleClear}
        sx={{ display: ['flex', 'none'] }}
      />

      <Popper
        open={open && isDesktop}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={250} style={{ transformOrigin: 'bottom' }}>
            <div>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <FilterList
                    open
                    width={width}
                    buttonClear={buttonClear}
                    handleToggle={handleToggle}
                    checked={checked}
                    variant={variant}
                    items={items}
                    noClear={noClear}
                    onClear={handleClear}
                    sx={{ display: ['none', 'flex'] }}
                  />
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </ErrorBoundary>
  );
};

const FilterList = ({ sx, open, width, items, buttonClear, handleToggle, checked, variant, noClear, onClear }: any) => (
  <FilterListRoot
    elevation={24}
    sx={{
      ...sx,
      width,
      maxHeight: open ? 600 : 0,
      transition: '0.3s ease-in-out',
      overflow: 'hidden',
      flexDirection: 'column',
    }}
  >
    {!noClear && (
      <FilterButtonsBox sx={{ width, display: ['none', 'flex'] }} variant={variant}>
        <Button
          sx={{
            '&:hover': {
              color: 'primary.contrastText',
            },
          }}
          onClick={onClear}
          variant="text"
        >
          {buttonClear}
        </Button>
      </FilterButtonsBox>
    )}

    <List sx={{ maxHeight: 540, overflowY: 'auto' }}>
      {items?.map((filter: any) => (
        <React.Fragment key={filter.value}>
          <Divider variant="middle" component="li" />
          <ListItem disablePadding>
            <ListItemButton
              color="secondary"
              sx={{ width }}
              selected={checked.indexOf(filter.value) !== -1}
              onClick={handleToggle(filter.value)}
            >
              <ListItemText primary={filter.label} />
              {checked.indexOf(filter.value) !== -1 && (
                // Fake clear button, as the whole list item is clickable
                <Box
                  sx={{
                    position: 'absolute',
                    right: 20,
                    top: '25%',
                    fontSize: 16,
                  }}
                >
                  x
                </Box>
              )}
            </ListItemButton>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  </FilterListRoot>
);

export default Filter;

const Button = styled(MuiButton, {
  name: 'Filter',
  slot: 'root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>(({ theme, variant }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: 'auto',
  marginBottom: 0,
  padding: '14px 18px',
  backgroundColor: variant === 'outlined' ? theme.palette.secondary.main : theme.palette.primary.main,
  borderRadius: '10px',
  filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.1))',
  color: variant === 'outlined' ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText,
  fontSize: 15,
  lineHeight: 1,
  minWidth: 'initial',
  '&:hover': {
    backgroundColor: variant === 'outlined' ? '#FCFCFC' : theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    height: 60,
    borderRadius: 10,
    maxWidth: '100%',
    marginBottom: 0,
  },
}));

const Paper = styled(MuiPaper, {
  name: 'Filter',
  slot: 'menu',
})<{ variant?: string }>(({ theme }) => ({
  marginBottom: theme.spacing(1),
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

const List = styled(MuiList, {
  name: 'Filter',
  slot: 'menuList',
})<{ variant?: string }>(() => ({
  '& .MuiListItem-root:nth-of-type(1)': {
    '& .MuiListItemButton-root': {
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
    },
  },
}));

const ListItem = styled(MuiListItem, {
  name: 'Filter',
  slot: 'menuListItem',
})<{ variant?: string }>(({ theme }) => ({
  '& :hover': {
    '& .MuiListItemText-primary': {
      color: theme.palette.grey['400'],
    },
  },
}));

const ListItemButton = styled(MuiListItemButton, {
  name: 'Filter',
  slot: 'menuListItemButton',
})<{ variant?: string }>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: 50,
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiButton-root': {
    padding: 0,
  },
  '& .MuiTypography-root': {
    textAlign: 'left',
  },
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
}));

const ListItemText = styled(MuiListItemText, {
  name: 'Filter',
  slot: 'menuListItemText',
})<{ variant?: string }>(({ theme }) => ({
  '& .MuiListItemText-primary': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
  },
  '& .MuiTypography-root': {
    color: theme.palette.secondary.contrastText,
    textAlign: 'left',
  },
}));

const Divider = styled(MuiDivider, {
  name: 'Filter',
  slot: 'divider',
})<DividerProps<React.ElementType>>(({ theme }) => ({
  borderColor: theme.palette.grey['100'],
}));

const FilterButtonsBox = styled(Box, {
  name: 'Filter',
  slot: 'filterButtons',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>(({ theme, variant }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 60,
  borderRadius: '0px 0px 10px 10px',
  backgroundColor: variant === 'outlined' ? theme.palette.secondary.main : theme.palette.primary.main,
  '& .MuiButton-root': {
    maxHeight: 40,
    margin: 'auto 8px auto 8px',
    fontWeight: 'bold',
    backgroundColor: variant === 'outlined' ? theme.palette.secondary.main : theme.palette.primary.main,
    color: variant === 'outlined' ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      minWidth: 100,
      maxWidth: 150,
    },
  },
}));

const FilterListRoot = styled(Paper, {
  name: 'Filter',
  slot: 'filterListRoot',
})<{ variant?: string }>(() => ({}));
