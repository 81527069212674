import React from 'react';
import { useRouter } from 'next/router';
import { Pagination as MuiPagination, PaginationProps as MuiPaginationProps, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';

import { useLocalizationContext } from '../LocalizationContext';

export interface PaginationProps extends MuiPaginationProps {
  count: number;
}

export const Pagination = ({ count, ...props }: PaginationProps) => {
  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const localization = useLocalizationContext();
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    // Find a better way of adding query strings
    router.query.page = String(value);
    router.push(router, undefined, { shallow: true });
    setPage(value);
  };

  React.useEffect(() => {
    const { page } = router.query;
    setPage(page ? Number(page) : 1);
  }, [router.query]);

  return (
    <ErrorBoundary>
      <Root
        previousLabel={localization['common.paginationPreviousLabel']?.shortTextValue ?? 'Previous'}
        nextLabel={localization['common.paginationNextLabel']?.shortTextValue ?? 'Next'}
      >
        <MuiPagination
          data-testid={'Pagination-Root'}
          count={count}
          // TODO: Need to update primary palette, as this includes green color for active state
          color="primary"
          page={page}
          onChange={handleChange}
          {...props}
        />
      </Root>
    </ErrorBoundary>
  );
};

export default Pagination;

const Root = styled(Box, {
  name: 'Pagination',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ previousLabel: string; nextLabel: string; variant?: string }>(({ theme, previousLabel, nextLabel }) => ({
  '& .MuiPagination-ul': {
    [theme.breakpoints.up('md')]: {
      'li:first-of-type': {
        'button:after': {
          content: `'${previousLabel}'`,
          padding: '0px 10px 0px 10px'
        }
      },
      'li:last-of-type': {
        'button:before': {
          content: `'${nextLabel}'`,
          padding: '0px 10px 0px 10px'
        }
      }
    }
  },
  display: 'flex',
  justifyContent: 'center'
}));
