import React from 'react';
import { Box, Typography, Grid, Button, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import omit from 'lodash/omit';

import Filter, { FilterProps } from '../Filter';
import { CommonResourceProps } from '../CommonResource';
import { useLocalizationContext } from '../LocalizationContext';

export interface FiltersProps {
  filters: FilterProps[];
  sort?: FilterProps;
  sx?: any;
}

interface MobileDialogProps extends FiltersProps {
  open: boolean;
  localization: {
    [key: string]: CommonResourceProps;
  };
  handleClose: () => void;
}

export const Filters = ({ filters, sort, sx }: FiltersProps) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const localization = useLocalizationContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ErrorBoundary>
      <FiltersRoot
        container
        display="flex"
        justifyContent="space-between"
        mt={0}
        mb={[3, 8]}
        rowSpacing={2}
        columnSpacing={5}
        sx={{
          flexDirection: { md: 'column', lg: 'row' },
          flexWrap: 'nowrap',
          color: theme.palette.secondary.contrastText,
          ...sx
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { md: 'none' }
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{
              justifyContent: 'space-between',
              borderColor: 'secondary.contrastText',
              color: 'secondary.contrastText'
            }}
          >
            {localization['common.filterTitle']?.shortTextValue ?? 'Filter'}
            <Typography variant="h4" color="primary">
              +
            </Typography>
          </Button>
        </Grid>
        <MobileDialog
          open={open}
          filters={filters.filter((item) => !item.hidden)}
          sort={sort}
          localization={localization}
          handleClose={handleClose}
        />
        <Grid
          item
          container
          rowSpacing={2}
          columnSpacing={5}
          xs={12}
          sx={{
            display: 'none',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }
          }}
        >
          <Grid item>
            <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 900 }}>
              {localization['common.filterByLabel']?.shortTextValue ?? 'Filter by:'}
            </Typography>
          </Grid>
          {filters
            .filter((item) => !item.hidden)
            .map((filter) => (
              <Grid key={filter.id} item sx={{ maxWidth: theme.spacing(37) }} xs>
                <Filter {...omit(filter, 'key')} />
              </Grid>
            ))}
        </Grid>

        {sort && (
          <Grid
            item
            container
            rowSpacing={2}
            columnSpacing={5}
            xs={12}
            sx={{
              display: 'none',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              [theme.breakpoints.up('md')]: {
                maxWidth: 422,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              },
              [theme.breakpoints.down('lg')]: {
                maxWidth: 'none',
                justifyContent: 'flex-start'
              },
              [theme.breakpoints.up('xl')]: {
                alignItems: 'center',
                flexDirection: 'row'
              }
            }}
          >
            <Grid
              item
              sx={{
                [theme.breakpoints.down('lg')]: {
                  minWidth: '139.75px'
                }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: 16,
                  fontWeight: 900
                }}
              >
                {localization['common.sortByLabel']?.shortTextValue ?? 'Sort by:'}
              </Typography>
            </Grid>
            <Grid item sx={{ maxWidth: theme.spacing(37) }} xs>
              <Filter {...sort} />
            </Grid>
          </Grid>
        )}
      </FiltersRoot>
    </ErrorBoundary>
  );
};

const MobileDialog = ({ filters, open, handleClose, sort, localization }: MobileDialogProps) => (
  <MobileDialogRoot
    fullScreen
    open={open}
    onClose={handleClose}
    sx={{
      pt: 8
    }}
  >
    <DialogTitle sx={{ my: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2">{localization['common.filterByLabel']?.shortTextValue ?? 'Filter by:'}</Typography>
        <Grid container sx={{ maxWidth: 200 }}>
          <Grid item xs={8} sx={{ justifyContent: 'space-evenly' }}>
            <MobileFilterButton variant="contained" onClick={handleClose}>
              {localization['common.resetAllLabel']?.shortTextValue ?? 'Reset all'}
            </MobileFilterButton>
          </Grid>
          <Grid item xs={4}>
            <MobileFilterButton variant="contained" onClick={handleClose}>
              X
            </MobileFilterButton>
          </Grid>
        </Grid>
      </Box>
    </DialogTitle>
    <DialogContent>
      {filters.map((filter) => (
        <React.Fragment key={filter.id}>
          <Filter {...omit(filter, 'key')} />
          <Divider sx={{ borderColor: 'primary.main', my: 5 }} />
        </React.Fragment>
      ))}
      <Box mb={2}>
        <Typography gutterBottom variant="body2">
          {localization['common.sortByLabel']?.shortTextValue ?? 'Sort by:'}
        </Typography>
      </Box>
      {sort && <Filter {...sort} />}
      <Divider sx={{ borderColor: 'primary.main', my: 5 }} />
      <Button variant="contained" onClick={handleClose}>
        {localization['common.applyFiltersLabel']?.shortTextValue ?? 'Apply filters'}
      </Button>
    </DialogContent>
  </MobileDialogRoot>
);

export default Filters;

const FiltersRoot = styled(Grid, {
  name: 'Filter',
  slot: 'root'
})<{ variant?: string }>(({ theme }) => ({
  '& .MuiButton-root': {
    width: '100%',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      minWidth: 120
    }
  }
}));

const MobileDialogRoot = styled(Dialog, {
  name: 'Filter',
  slot: 'mobileDialogRoot'
})<{ variant?: string }>(() => ({
  zIndex: 1,
  '& .MuiPaper-root': { backgroundColor: '#EFECEC' }
}));

const MobileFilterButton = styled(Button, {
  name: 'MobileDialog',
  slot: 'mobileFilterButton'
})<{ variant?: string }>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
    borderRadius: 13,
    height: 30,
    fontSize: 13,
    maxWidth: 120
  },
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: '#FCFCFC',
    color: theme.palette.primary.light
  }
}));
